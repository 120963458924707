import { axiosInstance } from "../baseUrl";
import React, { useEffect, useState, createContext } from "react";
import { useNavigate } from "react-router-dom";

export const GlobalContext = createContext();

const GlobalState = ({ children }) => {
  const [userLoading, setUserLoading] = useState(false);
  const [user, setUser] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [totalCartQuantity, setTotalCartQuantity] = useState(0);
  const [rerender, setRerender] = useState(true);

  const getUser = () => {
    axiosInstance
      .get(`/auth/current-user`)
      .then((response) => {
        setUser(response.data.currentUser);
        setUserLoading(false);
      })
      .catch((error) => {
        setUserLoading(false);
        setUser(null);
        localStorage.setItem("user", false);
      });
  };

  const getCart = async () => {
    try {
      const data = await axiosInstance.get("/cart");
      setTotalCartQuantity(data.data.data.count);
      setCartItems(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("user");
    if (user || isLoggedIn) {
      getCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerender]);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("user");
    if (isLoggedIn) {
      setUserLoading(true);
      setUser(isLoggedIn);
      getUser();
    }
  }, []);

  const value = {
    user,
    setUser,
    userLoading,
    cartItems,
    totalCartQuantity,
    setCartItems,
    setTotalCartQuantity,
    setRerender,
    rerender,
  };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export default GlobalState;
